import { Routes, Route } from "react-router-dom";
import Users from "./Users";
import ShowUser from "./ShowUser";
const RoutesUsers = () => {
  return (
    <Routes>
      <Route path="/" element={<Users />} />
      <Route path="/:userid" element={<ShowUser />} />
    </Routes>
  );
};

export default RoutesUsers;
