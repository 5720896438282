import { useEffect, useState } from "react";
import ListUsers from "../../Component/ListUsers";

const IndexUsers = () => {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://jsonplaceholder.typicode.com/users/")
      .then((response) => response.json())
      .then((json) => {
        setUsers(json);
        setLoading(false);
        setError(null);
      })
      .catch(err => {
        setError(err.message);
        console.log(err.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container my-5">
      <div className="row g-3">
        <h1 className="title">All Users:</h1>
        {error && <div>{error}</div>}
        {loading && <div className="spinner-border text-primary"></div>}
        {users && <ListUsers users={users} />}
      </div>
    </div>
  );
};

export default IndexUsers;
