import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const DeletePost = () => {
  const post = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetch(`https://jsonplaceholder.typicode.com/posts/${post.postid}`, {
      method: "Delete",
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setLoading(false);
        setError(null);
        Swal.fire({
          icon: "success",
          text: `Post ID ${post.postid} Delete Successfully`,
        });
        navigate("/posts");
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  }, [post ,navigate]);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        {error && <div className="text-danger mt-1">{error}</div>}
        {loading && <div className="spinner-border text-warning"></div>}
      </div>
    </div>
  );
};

export default DeletePost;
