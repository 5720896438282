import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";

const ShowPost = () => {
  const post = useParams();
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://jsonplaceholder.typicode.com/posts/${post.postid}`)
      .then((response) => response.json())
      .then((json) => {
        setPosts(json);
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        console.log(err.message);
        setLoading(false);
      });
  }, [post]);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        {error && <div>{error}</div>}
        {loading && <div className="spinner-border text-primary"></div>}
        {posts && (
          <div className="col-md-4" key={posts.id}>
            <h1 className="title my-3">UserID: {posts.id}</h1>
            <div className="card text-center">
              <div className="card-header">
                <span>Title: </span>
                {posts.title}
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <span>Body: </span>
                  {posts.body}
                </li>
              </ul>
              <div className="card-footer text-body-secondary">
                <Link className="btn btn-dark ms-5" to="/posts">
                  Posts
                </Link>
                <Link
                  className="btn btn-info ms-5"
                  to={`/posts/EditPost/${posts.id}`}
                >
                  Edit
                </Link>
                <Link className="btn btn-danger ms-5"  to={`/posts/DeletePost/${posts.id}`}>
                  Delete
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowPost;
