import { Routes, Route } from "react-router-dom";
import Post from "./Post";
import CreatePost from "./CreatePost";
import ShowPost from "./ShowPost";
import EditPost from "./EditPost";
import DeletePost from "./DeletePost";

const RoutesUsers = () => {
  return (
    <Routes>
      <Route path="/" element={<Post />} />
      <Route path="/CreatePost" element={<CreatePost />} />
      <Route path="/:postid" element={<ShowPost />} />
      <Route path="/EditPost/:postid" element={<EditPost />} />
      <Route path="/DeletePost/:postid" element={<DeletePost />} />
    </Routes>
  );
};

export default RoutesUsers;
