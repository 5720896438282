import { Link } from "react-router-dom";

const ListUsers = ({ users }) => {
  return (
    <>
      {users.map((user) => (
        <div className="col-md-3" key={user.id}>
          <div className="card" >
            <div className="card-header">
              <Link to={`/users/${user.id}`}>{user.name}</Link>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <span>ID: </span>
                {user.id}
              </li>
              <li className="list-group-item">
                <span>UserName: </span>@{user.username}
              </li>
              <li className="list-group-item">
                <span>Email: </span>
                {user.email}
              </li>
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default ListUsers;
