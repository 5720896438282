import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CreatePost = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const creatPost = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch("https://jsonplaceholder.typicode.com/posts", {
      method: "POST",
      body: JSON.stringify({
        title: title,
        body: body,
        userId: 1,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        setError(null);
        Swal.fire({
          icon: "success",
          text: "Post Created Successfully",
        });
        navigate("/posts");
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="text-center mb-3">
          <h1 className="title">Create Post</h1>
        </div>

        <div className="col-md-6">
          <form onSubmit={(e) => creatPost(e)}>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                className="form-control"
              />
              <div className="form-text text-danger">
                {title ? "" : "The Title is Required"}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="body" className="form-label">
                Body
              </label>
              <textarea
                onChange={(e) => setBody(e.target.value)}
                className="form-control"
                rows={3}
              ></textarea>
              <div className="form-text text-danger">
                {body ? "" : "The Body is Required"}
              </div>
            </div>
            <div className="text-center my-5">
              <button
                type="submit"
                className="btn btn-success"
                disabled={title === "" || body === ""}
              >
                {loading && (
                  <div className="spinner-border text-dark spinner-border-sm me-2"></div>
                )}
                Create
              </button>
              {error && <div className="text-danger mt-1"></div>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreatePost;
