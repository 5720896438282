import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
const EditPost = () => {
  const post = useParams();
  const [title, setTitle] = useState(" ");
  const [body, setBody] = useState(" ");
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetch(`https://jsonplaceholder.typicode.com/posts/${post.postid}`)
      .then((response) => response.json())
      .then((json) => {
        setPosts(json);
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [post]);
  const editPost = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(`https://jsonplaceholder.typicode.com/posts/${post.postid}`, {
      method: "PUT",
      body: JSON.stringify({
        id: 1,
        title: title,
        body: body,
        userId: 1,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setLoading(false);
        setError(null);
        Swal.fire({
          icon: "success",
          text: "Post Edited Successfully",
        });
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="text-center mb-3">
          <h1 className="title">Edit Post</h1>
        </div>
        {posts && (
        <div className="col-md-6">
          <form onSubmit={(e) => editPost(e)}>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                onChange={(e) => setTitle(e.target.value)}
                defaultValue={posts.title}
                type="text"
                className="form-control"
              />
              <div className="form-text text-danger">
                {title ? "" : "The Title is Required"}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="body" className="form-label">
                Body
              </label>
              <textarea
                defaultValue={posts.body}
                onChange={(e) => setBody(e.target.value)}
                className="form-control"
                rows={3}
              ></textarea>
              <div className="form-text text-danger">
                {body ? "" : "The Body is Required"}
              </div>
            </div>
            <div className="text-center my-5">
              <button
                type="submit"
                className="btn btn-success"
                disabled={title === "" || body === ""}
              >
                {loading && (
                  <div className="spinner-border text-dark spinner-border-sm me-2"></div>
                )}
                Edit
              </button>
              {error && <div className="text-danger mt-1">{error}</div>}
            </div>
          </form>
        </div>
        )}
      </div>
    </div>
  );
};

export default EditPost;
