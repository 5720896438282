import Header from "./Component/Header";
import { BrowserRouter ,Routes,Route } from "react-router-dom"; 
import Home from "./Pages/Home";
import RoutesUsers from "./Pages/users/RoutsUsers";
import RoutesPost from "./Pages/posts/RoutsPost";

function App() {
  return (
    <BrowserRouter>
        <Header/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/users/*" element={<RoutesUsers/>} />
          <Route path="/posts/*" element={<RoutesPost/>} />
        </Routes>
    </BrowserRouter>

  );
}

export default App;
