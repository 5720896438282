import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";

const ShowUser = () => {
  const user = useParams();
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://jsonplaceholder.typicode.com/users/${user.userid}`)
      .then((response) => response.json())
      .then((json) => {
        setUsers(json);
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        console.log(err.message);
        setLoading(false);
      });
  }, [user]);
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        {error && <div>{error}</div>}
        {loading && <div className="spinner-border text-primary"></div>}
        {users && (
          <div className="col-md-4" key={users.id}>
            <h1 className="title my-3">User: {users.id}</h1>
            <div className="card text-center">
              <div className="card-header">
                <span>Name: </span>
                {users.name}
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <span>ID: </span>
                  {users.id}
                </li>
                <li className="list-group-item">
                  <span>UserName: </span>@{users.username}
                </li>
                <li className="list-group-item">
                  <span>Email: </span>
                  {users.email}
                </li>
                <li className="list-group-item">
                  <span>Email: </span>
                  {users.phone}
                </li>
                <li className="list-group-item">
                  <span>City: </span>
                  {users.address.city}
                </li>
                <li className="list-group-item">
                  <span>Website: </span>
                  {users.website}
                </li>
                <li className="list-group-item">
                  <span>Company: </span>
                  {users.company.name}
                </li>
              </ul>
              <div className="card-footer text-body-secondary">
                <Link className="btn btn-outline-dark ms-5" to="/users">
                  Usrers
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowUser;
