import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Adipisci
            hic dolorem ipsam, voluptas officiis vero, maiores labore esse
            deserunt accusamus id repellendus quisquam corporis laudantium nemo.
            Consectetur ea rerum amet.
          </p>
          <Link className="btn btn-dark" to="/users">Users</Link>
          <Link className="btn btn-primary ms-5" to="/posts">Posts</Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
